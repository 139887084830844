import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import listBookingRsa from './components/listBookingRsa.vue'
import parenti from './components/parenti.vue'
import editParente from './components/editParente.vue'
import tipologiapagamento from './components/tipologiapagamento.vue'
import editTipologiaPagamento from './components/editTipologiaPagamento.vue'
import pagamenti from './components/pagamenti.vue'
import editPagamento from './components/editPagamento.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import copyPagamento from './components/copyPagamento.vue'
import confInvioMail from './components/confInvioMail.vue'
import reservate from './components/reservate.vue'
import categorie from './components/categorie.vue'
import editCategoria from './components/editCategoria.vue'
import sottocategorie from './components/sottocategorie.vue'
import editSottoCategoria from './components/editSottoCategoria.vue'
import configMail from './components/configMail.vue'
import gruppi from './components/gruppi.vue'
import editGruppo from './components/editGruppo.vue'
import ticketFilter from './components/ticketFilter.vue'
import ticketTree from './components/ticketTree.vue'
import editTicket from './components/editTicket.vue'
import userAssign from './components/userAssign.vue'
import autoAssign from './components/autoAssign.vue'
import logoFirma from './components/logoFirma.vue'
import checkLogoutPage from './components/checkLogoutPage.vue'
import filterImmagineSfondo from './components/filterImmagineSfondo.vue'
import initImmagineSfondo from './components/initImmagineSfondo.vue'
import immagineSfondo from './components/immagineSfondo.vue'
import changepasswordprofile from './components/changepasswordprofile.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import initTicketTree from './components/initTicketTree.vue'
import assocCategorieTemplateUtenti from './components/assocCategorieTemplateUtenti.vue'
import editAssocCategorieTemplateUtenti from './components/editAssocCategorieTemplateUtenti.vue'

import reservateFromProd from './components/reservateFromProd.vue'



Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/listBookingRsa',
            name: 'listBookingRsa',
            component: listBookingRsa
        }

        ,
        {
            path: '/parenti',
            name: 'parenti',
            component: parenti
        }

        ,
        {
            path: '/editParente/:id',
            name: 'editParente',
            component: editParente,
            props: true
        }

        ,
        {
            path: '/tipologiapagamento',
            name: 'tipologiapagamento',
            component: tipologiapagamento
        }


        ,
        {
            path: '/editTipologiaPagamento/:id',
            name: 'editTipologiaPagamento',
            component: editTipologiaPagamento,
            props: true
        }

        ,
        {
            path: '/pagamenti',
            name: 'pagamenti',
            component: pagamenti,
            props: true
        }

        ,
        {
            path: '/editPagamento/:id',
            name: 'editPagamento',
            component: editPagamento,
            props: true
        }

        ,
        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo,
            props: true
        }

        ,
        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        }

        ,
        {
            path: '/copyPagamento/:id',
            name: 'copyPagamento',
            component: copyPagamento,
            props: true
        }

        ,
        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail,
            props: true
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/categorie',
            name: 'categorie',
            component: categorie,
            props: true
        }

        ,
        {
            path: '/editCategoria/:id',
            name: 'editCategoria',
            component: editCategoria,
            props: true
        }



        ,
        {
            path: '/sottocategorie',
            name: 'sottocategorie',
            component: sottocategorie,
            props: true
        }

        ,
        {
            path: '/editSottoCategoria/:id',
            name: 'editSottoCategoria',
            component: editSottoCategoria,
            props: true
        }


        ,
        {
            path: '/configMail',
            name: 'configMail',
            component: configMail,
            props: true
        }

        ,
        {
            path: '/gruppi',
            name: 'gruppi',
            component: gruppi,
            props: true
        }

        ,
        {
            path: '/editGruppo/:id',
            name: 'editGruppo',
            component: editGruppo,
            props: true
        }


        ,
        {
            path: '/ticketFilter',
            name: 'ticketFilter',
            component: ticketFilter,
            props: true
        }

        ,
        {
            path: '/ticketTree',
            name: 'ticketTree',
            component: ticketTree,
            props: true
        }

        ,
        {
            path: '/editTicket/:id',
            name: 'editTicket',
            component: editTicket,
            props: true
        }

        ,
        {
            path: '/userAssign/:id',
            name: 'userAssign',
            component: userAssign,
            props: true
        },

      
        {
            path: '/autoAssign',
            name: 'autoAssign',
            component: autoAssign,
            props: true
        },

        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma,
            props: true
        },


        {
            path: '/checkLogoutPage/:id',
            name: 'checkLogoutPage',
            component: checkLogoutPage,
            props: true
        },


        {
            path: '/filterImmagineSfondo/',
            name: 'filterImmagineSfondo',
            component: filterImmagineSfondo,
            props: true
        },

        {
            path: '/initImmagineSfondo/',
            name: 'initImmagineSfondo',
            component: initImmagineSfondo,
            props: true
        },

        
        {
            path: '/immagineSfondo/',
            name: 'immagineSfondo',
            component: immagineSfondo,
            props: true
        },
        
        {
            path: '/changepasswordprofile/',
            name: 'changepasswordprofile',
            component: changepasswordprofile,
            props: true
        },

        {
            path: '/changepasswordinitial/',
            name: 'changepasswordinitial',
            component: changepasswordinitial,
            props: true
        },

        {
            path: '/initTicketTree/',
            name: 'initTicketTree',
            component: initTicketTree,
            props: true
        },

        {
            path: '/assocCategorieTemplateUtenti/',
            name: 'assocCategorieTemplateUtenti',
            component: assocCategorieTemplateUtenti,
            props: true
        },

        {
            path: '/editAssocCategorieTemplateUtenti/:id',
            name: 'editAssocCategorieTemplateUtenti',
            component: editAssocCategorieTemplateUtenti,
            props: true
        },



        {
            path: '/reservateFromProd/:id',
            name: 'reservateFromProd',
            component: reservateFromProd,
            props: true
        },


        

    ]
})


    router.afterEach((to, from) => {
        console.log ("from" , from  );
        console.log("going to " + to.fullPath)

        

    })


export default router
