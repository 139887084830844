import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISottoCategorie {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getCategorieByAzienda(id_azienda)
  {

    let formData = new FormData();

    formData.append("id_azienda",  id_azienda);

    return axios.post(this.getUrlDomain("getCategorieByAziendaApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  // async insSottoCategoriaApi(sottocategoria)
  // {

  //   let formData = new FormData();

  //   for ( var key in sottocategoria ) {
  //      formData.append(key, sottocategoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("insSottoCategoriaApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }



  async insSottoCategoriaApi(sottocategoria)
  {

    let formData = new FormData();

    for ( var key in sottocategoria ) {
       formData.append(key, sottocategoria[key]);
    }

    return axios.post(this.getUrlDomain("goInsertSottoCategoriaHdTool") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }





  // async getSottoCategoriaApi(id, user)
  // {


  //   return axios.get(this.getUrlDomain("getSottoCategoriaApi") + "/" + id + "?api=1&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }

  async getSottoCategoriaApi(id, user)
  {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("username", user);


    return axios.post(this.getUrlDomain("goEditSottoCategoriaHdTool") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  // async updSottoCategoriaApi(sottocategoria, id)
  // {


  //   let formData = new FormData();

  //   for ( var key in sottocategoria ) {
  //      formData.append(key, sottocategoria[key]);
  //   }

  //   return axios.post(this.getUrlDomain("updSottoCategoriaApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }

  async updSottoCategoriaApi(sottocategoria, id)
  {


    let formData = new FormData();

    formData.append("v_id", id);

    for ( var key in sottocategoria ) {
       formData.append(key, sottocategoria[key]);
    }

    return axios.post(this.getUrlDomain("goUpdateSottoCategoriaHdTool"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  // async delSottoCategoriaApi(id)
  // {

  //   return axios.get(this.getUrlDomain("delSottoCategoriaApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  async delSottoCategoriaApi(id)
  {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomain("goDeleteSottoCategoriaHdTool"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APISottoCategorie({
  url: "http://emtool.local/public/api/smartbook/"
})
