<template>
    <v-container class="w-container" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color: #35ad5d">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id = "itemAzienda"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="issues_category_template.companyid" interface="action-sheet"
                                disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_categoryid" style="cursor:pointer">
                        <ion-item id="itemCategoria"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Categoria </ion-label>

                            <ion-select id="idissues_category" name="idissues_category" class='idissues_category'
                                cancel-Text="Chiudi" done-Text="" :value="issues_category_template.idissues_category"
                                interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in categorie" :key="item.idissues_category"
                                    :value='item.idissues_category'> {{
                                        item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_templateid" style="cursor:pointer">
                        <ion-item id="itemGruppo"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Gruppo </ion-label>

                            <ion-select id="id_template" name="id_template" class='id_template' cancel-Text="Chiudi"
                                done-Text="" :value="issues_category_template.id_template" interface="action-sheet"
                                disabled readonly>

                                <ion-select-option v-for="item in template" :key="item.id" :value='item.id'> {{
                                    item.name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>




        </v-form>

        <div class="contgruppi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetTemplate" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headersheetTemplate" fixed style="position:fixed;">
                                Gruppi
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetTemplate = !sheetTemplate">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="search"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredTemplate" :key="item.id"
                                        @click="manageClickTemplate(item)">

                                        <v-img alt="" src="@/assets/gruppi.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contcategorie">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCategorie" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headersheetTemplate" fixed style="position:fixed;">
                                Categorie
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetCategorie = !sheetCategorie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCategoria"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCategorie"
                                        :key="item.idissues_category" @click="manageClickCategoria(item)">

                                        <v-img alt="" src="@/assets/categorie.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.description
                                            }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="search"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/azienda-hdtool.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                        <v-icon class='iconPlusList'
                                            aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

    </v-container>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";
import apicategorie from "../utils/categorie/apicategorie";

import {
    bus
} from "../main";

import router from ".././router";
import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        this.$root.$children[0].initDivError("#d_importo", "Importo richiesto");
        this.$root.$children[0].initDivError("#d_pagato", "");
        this.$root.$children[0].initDivError("#d_importo_rimanente", "");

        if (this.$route.params.id == 0) {

            this.titolo = "Inserimento Assoc. Cat. Gruppi Utenti";

            setTimeout(() => {
                this.initInsertAssoc();
            }, 100);

        }


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Assoc. Cat. Gruppi Utenti";

            setTimeout(() => {
                this.syncAssoc();
            }, 100);

        }

        // this.$root.$children[0].addClassError("#d_importo");

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":

                    router.push({
                        path: "/assocCategorieTemplateUtenti"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);


                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '#cont_templateid', function (event) {
                console.log(event);

                pointerVue.sheetTemplate = true;

            });

            $('body').on('click', '#cont_categoryid', function (event) {
                console.log(event);

                pointerVue.sheetCategorie = true;

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);


                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTemplate() {

            return _.orderBy(this.template.filter(item => {
                if (!this.search) return this.template;
                return (item.name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredCategorie() {

            return _.orderBy(this.categorie.filter(item => {
                if (!this.searchCategoria) return this.categorie;
                return (item.description.toLowerCase().includes(this.searchCategoria.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        sheetAziende: false,


        categorie: [],

        categorieTemp: [],


        sheetCategorie: false,

        searchCategoria: "",



        template: [],

        issues_category_template: {
            id_template: "",
            idissues_category: "",
            companyid: ""
        },

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Categoria",

        cur_id: 0,

        myValue: 0,

        sheetTemplate: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",



        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        categoria: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        codeRules: [
            (v) => !!v || "Codice richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('info_global')).aziende,

        tipologie: JSON.parse(localStorage.getItem('info_global')).tipologie_pagamento,

        stati_pagamento: JSON.parse(localStorage.getItem('info_global')).stati_pagamento

    }),

    methods: {

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.issues_category_template.companyid = azienda.companyid;

            this.setCategorie(azienda.companyid);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickTemplate: async function (gruppo) {

            console.log("AZI SEL: ", gruppo);

            this.sheetTemplate = false;

            this.issues_category_template.id_template = gruppo.id;

        },

        manageClickCategoria: async function (categoria) {

            console.log("AZI SEL: ", categoria);

            this.sheetCategorie = false;

            this.issues_category_template.idissues_category = categoria.idissues_category;

        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-hdtool.png",
                    title: "Salva",
                    width: 30

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back-hdtool.png",
                    //class: "inverted",
                    title: "Indietro",
                    width: 30

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            if (that.issues_category_template.companyid == "") {
                errore = "Specificare l'azienda"
            }

            if (errore == "") {
                if (that.issues_category_template.id_template == "") {
                    errore = "Specificare il gruppo"
                }
            }

            if (errore == "") {
                if (that.issues_category_template.idissues_category == "") {
                    errore = "Specificare la categoria"
                }
            }

            if (errore == "") {

                that.saveData();
            }
            else {
                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });
            }



        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        saveData: async function () {

            // var v_token = window.$cookies.get("token");
            var that = this;

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                response = await apicategorie.insertAssocCatGruppo(
                    that.issues_category_template

                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertAssocCatGruppo", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/assocCategorieTemplateUtenti"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }


            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicategorie.updateAssocCatGruppo(
                    that.issues_category_template,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateAssocCatGruppo", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/assocCategorieTemplateUtenti"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        setCategorie: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("CAT TMP: ", this.categorieTemp);

            var Categorie = [];

            for (var i = 0; i < this.categorieTemp.length; i++) {

                if (parseInt(this.categorieTemp[i].companyid) == parseInt(id_azienda)) {

                    Categorie.push({
                        idissues_category: this.categorieTemp[i].idissues_category,
                        description: this.categorieTemp[i].description,
                        companyid: this.categorieTemp[i].companyid
                    });

                }

            }

            console.log("CAT SEL: ", Categorie);

            this.categorie = Categorie;

        },

        initInsertAssoc: async function () {


            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicategorie.initInsertAssoc(
                0
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertAssoc", res);

                that.template = res.data.gruppi;

                //that.categorie = res.data.categorie;


                that.categorieTemp = res.data.categorie;






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAssoc: async function () {


            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicategorie.editAssocCatGruppo(
                that.$route.params.id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editAssocCatGruppo", res);

                that.template = res.data.gruppi;

                //that.categorie = res.data.categorie;
                that.categorieTemp = res.data.categorie;

                that.setCategorie(res.data.assoc_cat_gruppo[0].companyid);

                that.issues_category_template = res.data.assoc_cat_gruppo[0];

                $("#itemGruppo").addClass("item-has-value");

                $("#itemCategoria").addClass("item-has-value");

                $("#itemAzienda").addClass("item-has-value");


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.align_close_sheet {
    top: 23px !important
}

.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headersheetTemplate {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}
</style>